<template>
  <v-container>
    <v-card flat>
      <DialogImage
        v-if="dialogImage"
        :dialogImage.sync="dialogImage"
        :item="image"
      />
      <v-card-text class="pt-6">
        <div v-if="comentarios.length">
          <v-card
            class="mb-3"
            v-for="(comentario, i) in comentarios"
            :key="i"
            flat
            outlined
          >
            <v-card-text class="grey lighten-5">
              <div class="px-6 py-2 d-flex align-center justify-center">
                <span class="text-subtitle-1">
                  <span class="text-h5 font-weight-bold">{{
                    comentario.assunto_descricao
                  }}</span>
                  -
                  {{ comentario.nome_usuario }}
                </span>
                <v-spacer></v-spacer>
                {{ comentario.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
              </div>
              <v-divider> </v-divider>
              <div class="pa-6">
                {{ comentario.comentario }}
              </div>
              <v-divider></v-divider>
              <div class="d-flex pt-6 px-6">
                <div v-for="(midia, j) in comentario.midias" :key="j">
                  <v-btn icon v-if="midia.tipo == 'pdf'" :href="midia.url">
                    <v-icon>
                      mdi-file-pdf-box
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-else-if="midia.tipo == 'jpg' || midia.tipo == 'png'"
                    @click="openImage(midia.url)"
                  >
                    <v-icon>
                      mdi-image
                    </v-icon>
                  </v-btn>
                  <v-btn icon v-else target="_blank" :href="midia.url">
                    <v-icon>
                      mdi-file-document-outline
                    </v-icon>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <div>
                  <v-btn
                    @click="comentarioLido(comentario.id, i)"
                    v-if="comentario.status === 0"
                    class="primary"
                  >
                    OK
                  </v-btn>
                  <span v-else class="green--text">
                    <v-icon class="green--text">mdi-check</v-icon>
                    LIDO
                  </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div v-else class="d-flex align-center text-center justify-center">
          Nenhum comentário registrado até o momento!
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getComentarios, putMsgLida } from "@/api/gestor/comentarios.js";

import { mapActions } from "vuex";

export default {
  name: "Comentarios",

  props: {
    status: {
      type: Number,
    },
  },

  data() {
    return {
      dialogComentario: false,
      comentarios: [],
      loading: false,
      image: null,
      dialogImage: false,
    };
  },

  components: {
    DialogImage: () => import("./components/DialogImage.vue"),
  },

  methods: {
    openImage(item) {
      this.dialogImage = true;
      this.image = item;
    },
    fetchComentarios() {
      this.loading = true;
      getComentarios(`?&status=${this.status}`)
        .then((response) => {
          this.comentarios = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    comentarioLido(item, index) {
      putMsgLida(item).then((response) => {
        if (response.status === 200) {
          this.$toast.success("Comentário Lido!");
          this.comentarios[index].status = 1;
          this.STORE_count_comentarios();
        }
      });
    },
    ...mapActions("Comentarios", {
      STORE_count_comentarios: "countComentarios",
    }),
  },

  mounted() {
    this.fetchComentarios();
  },
};
</script>

<style></style>
